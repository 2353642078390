import React, {Component} from 'react';
import log_sap from './includes/logs/log_1_sap_extract_last_updated'
import log_prod from './includes/logs/log_2_prodution_delays_last_updated'
import log_com from './includes/logs/log_3_commercial_invoice_last_updated'
import log_inv from './includes/logs/log_4_inventory_update_last_updated'

export default class Dashboard extends Component {
  constructor(props){
    super(props)
    this.hourlyThreshold = this.hourlyThreshold.bind(this)
  }

  hourlyThreshold(input_date, input_hour, option){
    var date = new Date(input_date)
    var month = date.getMonth()
    var day = date.getUTCDate()
    var hour = date.getUTCHours()
    date.setMonth(day-1)
    date.setUTCDate(month+1)
    if(option === "day"){
      date.setUTCDate(date.getUTCDate()+input_hour)
    } else {
      date.setUTCHours(hour+input_hour)
    }

    var cur = Date.now()

    if (date < cur){
      console.log('Past')
      return true
    } else {
      console.log('Ok')
      return false
    }
  
  }
  
  render(){
    var date = new Date(log_sap['last updated'])
    var month = date.getMonth()
    var day = date.getUTCDate()
    date.setMonth(day-1)
    date.setUTCDate(month+1)
    date = date.toString()

    return(
      <div className="page-container">
        <div className={`container-item ${this.hourlyThreshold(log_sap['last updated'], 1, 'hours') ? `check` : `okay`}`}>
          <h1>SAP Extract</h1>
          <h2>Last updated: {log_sap['last updated']}</h2>
        </div>
        <div className={`container-item ${this.hourlyThreshold(log_prod['last updated'], 1, 'hours') ? `check` : `okay`}`}>
          <h1>Production Delays</h1>
          <h2>Last updated: {log_prod['last updated']}</h2>
        </div>
        <div className={`container-item ${this.hourlyThreshold(log_com['last updated'], 1, 'day') ? `check` : `okay`}`}>
          <h1>Commercial Invoice</h1>
          <h2>Last updated: {log_com['last updated']}</h2>
        </div>
        <div className={`container-item ${this.hourlyThreshold(log_inv['last updated'], 1, 'day') ? `check` : `okay`}`}>
          <h1>FSL Database</h1>
    <h2>Last updated: {log_inv['last updated']}</h2>
        </div>
      </div>
    );
  }
}
